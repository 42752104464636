<template>
          <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sign__content">
                        <!-- authorization form -->
                        <template v-for="(trailer, index) in content" v-bind:key="index">
                        <form class="sign__form" >
                            <a href="index.php" class="sign__logo">
                                <img src="assets/img/logoSvg.svg" style="width: 120px;" alt="">
                            </a>
                            <div class="sign__group">
                                <div class="row">
                                    <div class="col-12 col-xl-12" >
                                        <!-- <p style="text-align: center; font-family: &quot;Cairo&quot;, sans-serif; font-size: 18px;">نشوف .. منصة الكترونية مدفوعة القيمة للعروض الفنية الحصرية ونافذة للمحتوي الترفيهي بمعايير عالمية ودقة عالية تجد من خلالها احدث الاعمال الفنية لنجومك المفضلين</p> -->
                                        <!-- article content -->
                                        <!-- <div class="article__content">
                                            <h3>{{ trailer.title_ar}}</h3>
                                            <p>{{ trailer.desc_ar}}</p>
                                        </div> -->
                                        <!-- end article content -->
                                        <!-- <video  controls="" class="plyr" controlsList="nodownload" style="margin-right: 0px;">
                                            <source :src="trailer.trailer" type="Video/mp4" size="720">
                                        </video> -->
                                        <br>
                                        <div class="article__content">
                                            <ul class="list">
                                                <li><h6></h6></li>
                                                <li><h6></h6></li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <center>
                                <div class="sign__group" style="justify-content: center;">
                                    <span class="article__content prompts" >إنضم الينا  الان وتمتع بتجربة فريدة قم بالضغط علي زر "اشتراك جديد</span>
                                    <a @click="Subscribe(trailer)" class="sign__btn pointer" >اشتراك جديد</a>
                                </div>
                                <div class="sign__group" style="justify-content: center;"> 
                                    <span class="article__content prompts" >أو اذا كنت مشترك مسبقا اضغط علي زر "مشترك مسبقا </span>
                                    <a @click.prevent="CheckLogin(trailer)" class="sign__btn" >مشترك مسبقا</a>
                                </div>
                            </center>
                        </form>
                        </template>
                        <!-- end authorization form -->
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
  name: 'TrailerComp',
  data() {
    return {
      // content: null,
    };
  },

  async setup() {
      const cookie = useCookie()
      const content = await axios.get('ContentById.php?CID='+cookie.getCookie("Content_id"))
      .then((response) => {return response.data.Content;});
      return {
         content,      
    };
    
  },

  methods: {
    getFeed: function (trailer) {
      this.$cookie.setCookie('cat_id', trailer.cat_id);
      this.$cookie.setCookie('cat_id', trailer.cat_id);
    //  window.location.href = trailer.Landing;
     // this.$router.go(trailer.landing);
    //  this.$router.push({ name: "Subscribe" });
    },
    CheckLogin: function (trailer){
        let loader = this.$loading.show({
        // Optional parameters
        container: this.oldNum ? null : this.$refs.formContainer,
        // canCancel: true,
        // onCancel: this.onCancel,
      });
      // simulate AJAX
     setTimeout(() => loader.hide(), 3 * 1000)
        const toast = useToast();
       if(this.$cookie.getCookie('mdn') == null){
           this.$cookie.setCookie("landing_content",trailer.id);
           this.$cookie.setCookie("product_code",trailer.product_code);
           this.$router.push({ path: "/Login" });
       }else{
        axios.get("CheckLoginByProduct_Code.php?msisdn="+this.phone+"&product_code="+trailer.product_code)
      .then((response) => {
          
          if(response.status==1){
              this.$router.push({ path: "/Content" });

          }else{
            toast.error("عفوا انت غير مشتراك في هذا القسم الرجاء الضغط علي زر إشتراك", {
                      timeout: 4000
                   });
                     this.$cookie.setCookie("landing_content",trailer.id);
        //  const landing = trailer.landing;
         window.location.href = "https://dsplp.sd.zain.com/?p="+trailer.product_code
          }
          
          });
           
       }
    },
    Subscribe : function (trailer){
         this.$cookie.setCookie("landing_content",trailer.id);
        //  const landing = trailer.landing;
         window.location.href = "https://dsplp.sd.zain.com/?p="+trailer.product_code
    }
    
  },
   


}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
@media (min-width: 786px) {
    .plyr {
max-width: 100%;    }
}
</style>